
import { Action } from "vuex-class";
import { Component, Ref, Mixins } from "vue-property-decorator";
import { FormBase, FormSubmit } from "@/components/forms";
import { bus } from "@/main";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { ActivitySeller } from "@/store/modules/activity-seller.store";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormSubmit,
    },
})
export default class DeleteActivitySellerModal extends Mixins(HasFormErrors) {
    @Action("activity-seller/delete") deleteActivitySeller!: (id: number) => Promise<ActivitySeller>;

    @Ref() form!: FormClass;

    show = false;

    activitySellerId: number = NaN;

    mounted() {
        bus.$off("show-delete-activity-seller");
        bus.$on("show-delete-activity-seller", (payload: number) => {
            this.show = true;
            this.activitySellerId = payload;
        });
    }

    async submit(form: FormClass) {
        try {
            if (!this.activitySellerId) {
                return;
            }
            await this.deleteActivitySeller(this.activitySellerId);

            this.$toast.open({ message: this.$t("views.activity_seller.delete.deleted") as string, type: "success", position: "bottom-right" });

            this.handleClose();
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        } finally {
            form.reset();
        }
    }

    handleClose() {
        this.clearErrors();

        this.show = false;
    }
}
